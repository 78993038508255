import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import PropTypes from 'prop-types';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { PopupModal } from 'react-calendly';
import ReactDOM from 'react-dom';
import { useUser } from 'reactfire';

import analytics from '../../app/analytics';
import Button from '../form/button';

function Calendly({ url, isOpen, close }) {
  useEffect(() => {
    const overlay = document.querySelector('.calendly-overlay');
    if (overlay) {
      if (isOpen) {
        overlay.addEventListener('click', close);
        return () => {
          overlay.removeEventListener('click', close);
        };
      }
      overlay.removeEventListener('click', close);
    }
    return () => {};
  }, [isOpen]);

  const { data: user } = useUser();
  const prefill = useMemo(() => {
    if (user?.email) {
      return { email: user.email };
    }
    return undefined;
  }, [user]);

  const utm = useMemo(() => {
    const returnObj = {};
    const urlParams = new URLSearchParams(window.location.search);
    let found = false;
    ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'].forEach((param) => {
      const value = urlParams.get(param);
      if (value) {
        found = true;
        returnObj[param] = value;
      }
    });
    if (found) {
      return returnObj;
    }
    return undefined;
  }, []);

  return (
    ReactDOM.createPortal(<PopupModal
      url={url}
      open={isOpen}
      onModalClose={close}
      pageSettings={{
        backgroundColor: '0d1117',
        hideEventTypeDetails: true,
        hideLandingPageDetails: true,
        primaryColor: 'ffffff',
        textColor: 'ffffff',
        hideGdprBanner: true,
      }}
      rootElement={document.body}
      utm={utm}
      prefill={prefill}
    />, document.body)
  );
}

Calendly.propTypes = {
  url: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  close: PropTypes.func.isRequired,
};

Calendly.defaultProps = {
  isOpen: false,
};

function HubSpot({ url: submittedURL, isOpen, close }) {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: 'calc(100vh - 2em)',
  };

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => { window.hbspt.meetings.create('.meetings-iframe-container'); }, 100);
    }
  }, [isOpen]);

  const url = useMemo(() => {
    const parsedURL = new URL(submittedURL);
    const embedded = parsedURL.searchParams.get('embed');
    if (!embedded) {
      parsedURL.searchParams.append('embed', true);
    }
    return parsedURL.href;
  }, [submittedURL]);

  const handleHubspotMessage = useCallback((event) => {
    if (event.isTrusted && event.data?.meetingBookSucceeded) {
      const email = event.data?.meetingsPayload?.bookingResponse?.postResponse?.contact?.email;
      const eventName = event.data?.meetingsPayload?.userSlug;
      analytics.meetingScheduled({
        userId_: 'not-used',
        eventName,
        email,
      });
      setTimeout(() => {
        close();
      }, 2000);
    }
  }, [close]);

  useEffect(() => {
    window.addEventListener('message', handleHubspotMessage);
    return () => {
      window.removeEventListener('message', handleHubspotMessage);
    };
  }, []);

  return (
    <Modal
      open={isOpen}
      onClose={close}
    >
      <Box sx={style}>
        <div className="meetings-iframe-container" data-src={url} style={{ minWidth: 400, minHeight: 400 }} />
      </Box>
    </Modal>
  );
}

HubSpot.propTypes = {
  url: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  close: PropTypes.func.isRequired,
};

HubSpot.defaultProps = {
  isOpen: false,
};

function CalendarButton({
  url, text, className, isCTA,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const close = () => setIsOpen(false);

  return (
    <>
      { url.includes('calendly') ? (
        <Calendly url={url} isOpen={isOpen} close={close} />
      ) : null}
      { url.includes('hubspot') ? (
        <HubSpot url={url} isOpen={isOpen} close={close} />
      ) : null}
      <Button
        onClick={() => {
          if (isCTA) {
            analytics.clickCta({
              cta: text,
              url: window.location.href.replace(window.location.origin, ''),
            });
          }
          setIsOpen(true);
        }}
        label={text}
        className={className}
      />
    </>
  );
}

CalendarButton.propTypes = {
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  isCTA: PropTypes.bool,
};

CalendarButton.defaultProps = {
  className: '',
  isCTA: false,
};

export { CalendarButton };
export default Calendly;
